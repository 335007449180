<template>
  <div class="contain404">
    <div>
      <img src="../../assets/images/404.png" />
      <div class="pdiv">
        <p class="p1">404.抱歉，您访问的资源不存在...</p>
        <p class="p2">
          可能是网址有误，或者对应的内容被删除，请详细检查或重新登录
        </p>
        <p class="p2">有任何问题，请与我们联系，邮箱地址：aoyu@jeansawn.com</p>
        <el-button class="tohomebtn" @click="$router.push('./home')"
          >去首页<i class="el-icon-arrow-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contain404 {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .pdiv {
    padding-left: 25px;
    .p1 {
      font-weight: bold;
      font-size: 15px;
      padding-top: 30px;
    }
    .p2 {
      color: #acb8c6;
      padding-top: 18px;
      font-size: 14px;
    }
    .tohomebtn {
      background-color: #4277c7;
      color: #ffffff;
      margin-top: 20px;
      padding: 0px 22px !important;
      height: 35px !important;
    }
  }
}
</style>